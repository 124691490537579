import React from 'react';

import SenseiIconSmallSvg from './SenseiIconSmall.svg';
import SenseiIconBigSvg from './SenseiIconBig.svg';
import { NIcon } from './styled';
import { styled } from '@mui/styles';

export interface Props {
    big?: boolean;
    height?: number | string;
    width?: number | string;
}

const Img = styled('img')(({ height, width }: Props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: `${width || '479px'}`,
    height: `${height || '102px'}`,
    background: 'transparent',
}));

export const PiIcon = ({ height }: { height?: number | string }) => (
    <NIcon height={height} />
);

const Icon = ({ big = false, height, width }: Props) => {
    return (
        <Img
            src={big ? SenseiIconBigSvg : SenseiIconSmallSvg}
            alt={''}
            height={height}
            width={width}
        />
    );
};

export default Icon;
