import React from 'react';

import { PiIcon } from '../../SenseiIcon';
import { PiSensei } from '@sensei/pi-component';
import { Grid, styled } from '@mui/material';
import { questionTitleSize, weightBold } from '../../App/components/fontStyles';

const MdIconContainer = styled('div')(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.up('sm')]: {
        display: 'block',
        width: '400px',
    },
}));

const SmIconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

const StyledGrid = styled(Grid)(() => ({
    width: '100vw',
    height: '100vh',
    backgroundImage: 'url(/static/images/background__welcome.jpg)',
    backgroundSize: 'cover',
}));

const CancelledDiV = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    fontSize: questionTitleSize,
    color: '#fff',
    fontWeight: weightBold,
    marginTop: '2rem',
}));

export default function IndexLoading() {
    return (
        <StyledGrid container justifyContent="center" alignItems="center">
            <MdIconContainer>
                <PiSensei color="secondary" width="100%" />
            </MdIconContainer>
            <SmIconContainer>
                <PiIcon height="100%" />
            </SmIconContainer>
        </StyledGrid>
    );
}

export function IndexCancelled() {
    return (
        <StyledGrid container justifyContent="center" alignItems="center">
            <MdIconContainer>
                <PiSensei color="secondary" width="100%" />
                <CancelledDiV>Invalid Link</CancelledDiV>
            </MdIconContainer>
            <SmIconContainer>
                <PiIcon height="100%" />
                <CancelledDiV>Invalid Link</CancelledDiV>
            </SmIconContainer>
        </StyledGrid>
    );
}
