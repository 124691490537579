import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors } from '@sensei/pi-component';

export const colorLightQuestion = colors.CARBON;
export const colorVeryLight = colors.GRAPHITE;
export const colorDefault = '#000';
export const weightLight = 300;
export const weightRegular = 400;
export const weightBold = 500;
export const xxlTitleSize = '2.75rem';
export const xlTitleSize = '2.5rem';
export const questionTitleSize = '1.625rem';
export const questionTitleMobileSize = '1.375rem';
export const questionTextSize = '1.375rem';
export const questionTextSmallSize = '1.25rem';
export const interestSelectionTextSmallSize = '1.125rem';
export const formTextSize = '1rem';
export const circleTextSize = '.9rem';
export const letterSpacingDefault = '0.01em';
export const letterSpacingWide = '0.04em';
export const questionMargin = '40px';
export const imageBrightness = 'brightness(60%)';

export const useFontStyles = makeStyles<Theme>(
    (theme) => ({
        xlTitle: {
            fontSize: xlTitleSize,
            fontWeight: weightRegular,
        },
        xlTitleSerif: {
            fontSize: xlTitleSize,
            fontWeight: weightRegular,
            fontFamily: 'Minion Pro',
        },
        questionTitle: {
            fontSize: questionTitleMobileSize,
            [theme.breakpoints.up('sm')]: {
                fontSize: questionTitleSize,
            },
            fontWeight: weightLight,
            letterSpacing: letterSpacingDefault,
        },
        questionText: {
            fontSize: questionTextSize,
            fontWeight: weightLight,
            letterSpacing: letterSpacingDefault,
        },
        questionTextSmall: {
            fontSize: questionTextSmallSize,
            fontWeight: weightLight,
            letterSpacing: letterSpacingDefault,
        },
        formText: {
            fontSize: formTextSize,
            fontWeight: weightRegular,
        },
        overline: {
            fontSize: questionTextSmallSize,
            fontWeight: weightRegular,
            textTransform: 'uppercase',
        },
        interestSelectionText: {
            fontSize: interestSelectionTextSmallSize,
            fontWeight: weightRegular,
            lineHeight: 1.5,
            color: colorLightQuestion,

            [theme.breakpoints.up('sm')]: {
                letterSpacing: letterSpacingWide,
                fontSize: interestSelectionTextSmallSize,
            },
        },
        interestSelectionTextBold: {
            fontSize: questionTextSize,
            fontWeight: weightRegular,
            letterSpacing: letterSpacingDefault,
        },
    }),
    { name: 'Font-Styles' },
);
