import React from 'react';

export interface IconProps {
    height?: number | string;
}

export const SIcon = ({ height }: IconProps) => (
    <svg
        width="75"
        height={`${height || 102}`}
        viewBox="0 0 75 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M46.523 45.0257L33.4455 41.7086C20.6553 38.4686 16.5496 34.8514 16.5496 26.7771C16.5496 17.6229 24.44 11.7086 36.6558 11.7086C47.4523 11.7086 55.9425 15.84 61.9236 23.9743L72.0105 16.44C67.4486 10.26 56.8042 0 36.8078 0C27.7347 0 19.4472 2.57143 13.4576 7.18286C7.01602 12.0517 3.30028 19.7866 3.48901 27.9343C3.48901 45.4371 15.5274 50.0657 30.0748 53.9486L41.9019 56.9743C54.312 60.1543 61.4928 63.8314 61.4928 73.3457C61.4928 83.7086 52.8674 90.1457 38.9705 90.1457C26.3746 90.1457 15.7555 84.3857 10.4332 74.7171L0 82.4829C7.60317 94.6886 21.7958 101.966 38.2609 101.966C60.1411 101.966 74.849 90.1714 74.849 72.6257C74.8152 55.8686 63.9849 49.6114 46.523 45.0257Z"
            fill="white"
        />
    </svg>
);

export const EIcon = ({ height }: IconProps) => (
    <svg
        width="65"
        height={`${height || 100}`}
        viewBox="0 0 65 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.7049 54.9801H60.0336V43.1686H13.7049V12.5344H64.5871V0.714355H0.779541V99.1829H64.5871V87.3629H13.7049V54.9801Z"
            fill="white"
        />
    </svg>
);

export const NIcon = ({ height }: IconProps) => (
    <svg
        width="77"
        height={`${height || 100}`}
        viewBox="0 0 77 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.165894 99.1829H13.0913V12.5344H63.9734V99.1829H76.8988V0.714355H0.165894V99.1829Z"
            fill="white"
        />
    </svg>
);

export const IIcon = ({ height }: IconProps) => (
    <svg
        width="14"
        height={`${height || 100}`}
        viewBox="0 0 14 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.483276 0.714355H13.4087V99.1829H0.483276V0.714355Z"
            fill="white"
        />
    </svg>
);
