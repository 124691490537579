import React from 'react';
import Head from 'next/head';
import IndexLoading from '../src/component/scene/IndexLoading';

export default function Page() {
    return (
        <>
            <Head>
                <title>PAQ 2.0</title>
            </Head>
            <main>
                <IndexLoading />
            </main>
        </>
    );
}
